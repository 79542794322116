import React, { useState } from "react";
import logo from "../images/Logo.svg";
import { Link } from "gatsby";
import * as styles from "../styles/navbarMobile.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const NavbarMobile = () => {
 const [isOpen, setIsOpen] = useState(false);

 const navbarStyles = {
  opacity: isOpen ? "1" : "0",
  visibility: isOpen ? "visible" : "hidden",
 };
 const navOpen = { backgroundColor: isOpen ? "black" : "transparent" };

 const handleBurgerClick = () => {
  setIsOpen((prev) => !prev);
  if (window.location.pathname === "/") {
   document.querySelector(".carousel-inner").classList.toggle("hidden");
   document
    .querySelector(".react-multi-carousel-list")
    .classList.toggle("hidden");
  }
 };

 const CustomLink = ({ text, href }) => {
  return (
   <Link
    onClick={() => {
     setIsOpen(false);
     if (window.location.pathname === "/") {
      document.querySelector(".carousel-inner").classList.toggle("hidden");
      document
       .querySelector(".react-multi-carousel-list")
       .classList.toggle("hidden");
     }
    }}
    to={href}
   >
    {text}
   </Link>
  );
 };

 return (
  <div className={styles.navi}>
   <div className={styles.naviWrapper} style={navOpen}>
    <button className={styles.hamburger} onClick={handleBurgerClick}>
     <img className={styles.naviLogo} src={logo} alt="company logo" />
    </button>
    <button className={styles.hamburger} onClick={handleBurgerClick}>
     {isOpen ? (
      <FontAwesomeIcon icon={faTimes} />
     ) : (
      <FontAwesomeIcon icon={faBars} />
     )}
    </button>
    <div className={styles.navbarMobileContent} style={navbarStyles}>
     <div className={styles.navbarList}>
      <CustomLink text={"O projekcie"} href={"/#o-projekcie"} />
      <CustomLink text={"Co robimy"} href={"/#co-robimy"} />
      <CustomLink text={"Aktualności"} href={"/#aktualnosci"} />
      <CustomLink text={"Zgłoś projekt"} href={"/#contact"} />
     </div>
    </div>
   </div>
  </div>
 );
};

export default NavbarMobile;

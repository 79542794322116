import React from "react";
import * as styles from "../styles/contactUs.module.css";

const ContactUs = () => {
 return (
  <section id="contact" className={styles.sectionWrapper}>
   <h3>Zgłoś swój projekt</h3>
   <p>
    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
    eirmod tempor invidunt.
   </p>
   <a href="mailto: testmail@gmail.com">NAPISZ DO NAS</a>
  </section>
 );
};

export default ContactUs;

import React, { useContext, useEffect, useState } from "react";
import { LangContext } from "./LangContext";
import * as styles from "../styles/navbar.module.css";
import { Link } from "gatsby";
import logoWhite from "../images/Logo.svg";
import logoBlack from "../images/Logo_b.svg";
import plFlag from "../images/pl.svg";
import enFlag from "../images/en.png";

const Navbar = () => {
 const { lang, pickLang } = useContext(LangContext);
 const [scrollHeight, setScrollHeight] = useState();
 const [isHomePage, setisHomePage] = useState(true);

 useEffect(() => {
  if (window.location.pathname !== "/") {
   setisHomePage(false);
  }
  const handleScroll = () => {
   setScrollHeight(window.scrollY);
  };

  document.addEventListener("scroll", handleScroll);
 }, []);

 const navStyle = {
  backgroundColor: isHomePage
   ? scrollHeight > 199
     ? "black"
     : "transparent"
   : "white",
 };

 const linkStyle = {
  color: isHomePage ? "white" : "black",
 };

 return (
  <nav className={styles.navbar} style={navStyle}>
   <div className={styles.navWrapper}>
    <div>
     <Link to="/">
      <img
       src={isHomePage ? logoWhite : logoBlack}
       alt={
        lang
         ? "Logo strony. Przekierowanie na stronę główną"
         : "Website logo. Redirection to the home page "
       }
      />
     </Link>
    </div>
    <div className={styles.langAndList}>
     <ul className={styles.navLinks}>
      <Link to="/#o-projekcie">
       <li style={linkStyle}>{lang ? "O projekcie" : "About project"}</li>
      </Link>
      <Link to="/#co-robimy">
       <li style={linkStyle}>{lang ? "Co robimy" : "Our Offer"}</li>
      </Link>
      <Link to="/#aktualnosci">
       <li style={linkStyle}>{lang ? "Aktualności" : "News"}</li>
      </Link>
      <Link to="/#contact">
       <li>{lang ? "Zgłoś projekt" : "Contact Us"}</li>
      </Link>
     </ul>
     <button
      className={styles.langPick}
      aria-label={
       lang ? "Zmień język na angielski" : "Change language to polish"
      }
      onClick={() => {
       pickLang((prevState) => !prevState);
      }}
      onKeyDown={(e) => {
       if (e.code === "Enter") {
        if (!lang) {
         pickLang(false);
        } else if (lang) {
         pickLang(true);
        }
       }
      }}
     >
      <img src={lang ? enFlag : plFlag} />
     </button>
    </div>
   </div>
  </nav>
 );
};

export default Navbar;

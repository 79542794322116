import React from "react";
import * as styles from "../styles/footer.module.css";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.companyInfo}>
          <ul>
            <li>NAZWA GMINY</li>
            <li>ul.Przykladowa 2/3</li>
            <li>58-100 miasto</li>
            <li>
              <a href="tel:+48123 123 123">telefon: 123 123 123</a>
            </li>
            <li>
              <a href="mailto:semail.gmail.com">e-mail: email.gmail.com</a>
            </li>
          </ul>
        </div>
        <div className={styles.linkList}>
          <ul>
            <li>
              <Link to="/#o-projekcie">O projekcie</Link>
            </li>
            <li>
              <Link to="/#co-robimy">Co robimy</Link>
            </li>
            <li>
              <Link to="/#aktualnosci">Aktualności</Link>
            </li>
            <li>
              <Link to="/#contact">Kontakt</Link>
            </li>
          </ul>
        </div>
        <div className={styles.socials}>
          <div>
            <p>Social Media</p>
            <div className={styles.socialsWrapper}>
              <a href="/" target="__blank">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="/" target="__blank">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="/" target="__blank">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>
            <Link className={styles.socialLink} to="/">
              Polityka prywatności i cookies
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import NavbarMobile from "./NavbarMobile";

const Layout = ({ children }) => {
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    window.innerWidth < 900 ? setIsDesktop(false) : setIsDesktop(true);
    const handleResize = () => (window.innerWidth < 900 ? setIsDesktop(false) : setIsDesktop(true));

    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {isDesktop ? <Navbar /> : <NavbarMobile />}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
